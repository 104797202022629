module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":896,"quality":50,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"КОМПАНІЯ «СНІЖНИЙ БАРС»","short_name":"СНІЖНИЙ БАРС","lang":"uk","description":"Ми пропонуємо широкий спектр послуг в сфері промальпа, замовляйте будівельні роботи на висоті від компанії Сніговий Барс.","start_url":"/","background_color":"#2e3246","theme_color":"#3498DB","display":"standalone","icon":"/home/runner/work/s-bars/s-bars/src/assets/images/icon.png","localize":[{"start_url":"/ru//","lang":"ru","name":"КОМПАНИЯ «СНЕЖНЫЙ БАРС»","short_name":"СНЕЖНЫЙ БАРС","description":"Мы предлагаем широкий спектр услуг в сфере промальпа, заказывайте строительные работы на высоте от компании Снежный Барс."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2cad4a617e7ed528e081e4455fa107a3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-24264154-1","anonymize":true,"allowLinker":true,"head":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
