exports.components = {
  "component---src-templates-404-jsx": () => import("./../../../src/templates/404.jsx" /* webpackChunkName: "component---src-templates-404-jsx" */),
  "component---src-templates-about-jsx": () => import("./../../../src/templates/about.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-blog-post-list-post-list-jsx": () => import("./../../../src/templates/blog/post-list/post-list.jsx" /* webpackChunkName: "component---src-templates-blog-post-list-post-list-jsx" */),
  "component---src-templates-blog-post-post-jsx": () => import("./../../../src/templates/blog/post/post.jsx" /* webpackChunkName: "component---src-templates-blog-post-post-jsx" */),
  "component---src-templates-contacts-jsx": () => import("./../../../src/templates/contacts.jsx" /* webpackChunkName: "component---src-templates-contacts-jsx" */),
  "component---src-templates-home-home-jsx": () => import("./../../../src/templates/home/home.jsx" /* webpackChunkName: "component---src-templates-home-home-jsx" */),
  "component---src-templates-inquiry-inquiry-jsx": () => import("./../../../src/templates/inquiry/inquiry.jsx" /* webpackChunkName: "component---src-templates-inquiry-inquiry-jsx" */),
  "component---src-templates-object-type-list-jsx": () => import("./../../../src/templates/object-type-list.jsx" /* webpackChunkName: "component---src-templates-object-type-list-jsx" */),
  "component---src-templates-object-type-object-type-jsx": () => import("./../../../src/templates/object-type/object-type.jsx" /* webpackChunkName: "component---src-templates-object-type-object-type-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-price-list-price-list-jsx": () => import("./../../../src/templates/price-list/price-list.jsx" /* webpackChunkName: "component---src-templates-price-list-price-list-jsx" */),
  "component---src-templates-service-service-jsx": () => import("./../../../src/templates/service/service.jsx" /* webpackChunkName: "component---src-templates-service-service-jsx" */),
  "component---src-templates-services-services-jsx": () => import("./../../../src/templates/services/services.jsx" /* webpackChunkName: "component---src-templates-services-services-jsx" */)
}

